import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    <Row className='mt-5 mb-3'>
        <Col md={3} lg={3} className="text-center">
            <h3>Support</h3>
            <div className='footer-links'>
                <Link to="/">Home</Link><br/>
                <Link to="/videos">Films</Link><br/>
                <Link to="/photos">Photos</Link><br/>
                <Link to="/about">About Us</Link><br/>
                <Link to="/contact">Contact Us</Link>
            </div>
        </Col>
        <Col md={3} lg={3} className="text-center">
            <h3>Address</h3>
            <p>
                <b>Aggarwal Digital Studio</b><br/>
                Tilak Bazaar, Hisar - 125001, Haryana
            </p>
        </Col>
        <Col md={3} lg={3}  className="text-center">
            <h3>Contact Info</h3>
            <p>
           <i class="bi bi-phone"></i>: +91 9034002889
            </p>
        
            <p>
           <i class="bi bi-envelope"></i>  :  info@aggarwalstudio.com
            </p>
        </Col>
        <Col md={3} lg={3}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3484.0026793100924!2d75.7219338!3d29.1645933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391232d105409c0d%3A0x1698e8ec76a39d67!2sAggarwal%20Digital%20Studio%20and%20Color%20Lab!5e0!3m2!1sen!2sin!4v1664523437960!5m2!1sen!2sin" width="100%" height="200" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Col>
    </Row>
    </>
  );
}

export default Footer;