import './App.css';
import { Home } from './Home';
import MainNavBar from './MainNavBar';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Card, Container, Row } from 'react-bootstrap';
import Footer from './components/Footer';
import Photos from './Photos';
import About from './About';
import { Blog } from './Blog';
import Contact from './Contact';
import BlogOne from './BlogOne';
import BlogTwo from './BlogTwo';
import BlogThree from './BlogThree';
import BlogFour from './BlogFour';
import Film from './Film';
function App() {
  return (
    <>
      <MainNavBar/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/photos" element={<Photos/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/reasons-to-hire-a-professional-wedding-photographer" element={<BlogOne/>}/>
            <Route path="/most-beautiful-pre-wedding-shoot-destinations-of-india" element={<BlogTwo/>}/>
            <Route path="/Wedding-Photography-9-Best-Tips-for-Wedding-Photographers" element={<BlogThree/>} />
            <Route path="/Why-You-Need-A-Candid-Wedding-Photographer-for-your-wedding" element={<BlogFour/>} />
            <Route path="/film" element={<Film/>} />
          </Routes>
          <div className='footer-img pt-4 pb-5'>
      <Container>
        <Footer/>
      </Container>
      </div>
      <div>
        <p className='pt-2 pb-2 text-white dark-bg text-center mb-0'>Copyright © 2022 <b>Aggarwal Digital Studio</b>. All Rights Reserved. Created with ♥ by <b><a className='text-white' rel="nofollow" href="https://www.havfly.com/">Havfly</a></b></p>
      </div>
    </>
  );
}

export default App;
