import { Row, Container } from 'react-bootstrap';
import AboutSection from './components/AboutSection';
import { ClientReview } from './components/ClientReview';
import {useEffect} from 'react';

function About() {
  useEffect(() => {
    window.scrollTo({ top:0, behavior: 'smooth' })
  }, [])
  return (
    <>
    <Container>
    <Row >
        <AboutSection aboutContentfirst ="Aggarwal Digital Studio is one of the best wedding photographers in Hisar. They are exclusively interested in photography as art. They are famous for their creativity when it comes to capturing the original essence and moments of occasions, such as candid wedding photography and pre-wedding photography. Your precious moments deserve to be captured and frozen in time in the unique, genuine, and elegant ways that Aggarwal Digital Studios' experienced team can do."
        aboutContentSecond=" Hisar is a city where things are always occurring and the competition is very high, yet despite this, we are here for you, giving you the happiest moments of a lifetime with our artistic and stylistic services. Love may be in the air, hidden from all eyes but our camera captures it by capturing those quiet details that arise."/>
    </Row>
    </Container>
    <ClientReview/>
    </>
  );
}

export default About;