import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import bannerThree from "./img/slide4.jpg";
import {useEffect} from 'react';
const BlogFour = () => {
    useEffect(() => {
        window.scrollTo({ top:0, behavior: 'smooth' })
      }, [])
  return (
    <>
     <Container>
       <Col className='mt-5'>
                <h2 className="dark-text mb-4"> 
                Why You Need A Candid Wedding Photographer for your wedding
                 </h2>
                 <img src={bannerThree}/>
                <h2 className="dark-text  mt-2 mb-2">Need To Hire A Professional Candid Wedding Photographer</h2>
                <p className="text-under-link">
                It seems quite boring to spend hours posing for photos on a stage with different individuals. But when non-professional photographers create the wedding album, most brides and grooms are forced to make that decision.
                </p>

            <p className="text-under-link" > <Link to="/">Best Wedding photographers</Link> that capture candid moments can help with this. Indian wedding photographers are increasingly upgrading their techniques and rejecting static pictures of simply the bride and groom. Nowadays, it's more important to get natural images of the entire wedding party and capture moments that are significant to the bride and groom.</p>

            <p className="text-under-link">Even though candid wedding photographers are more common at Indian weddings, some couples choose to avoid hiring them in the mistaken belief that the non-professional photographer would be able to handle the task. That never takes place. The bride and groom will instead be given dull pictures of their whole wedding. And we can guarantee that's not what you want
 </p>

            <h4 className="dark-text mt-2 mb-2">Mehendi Swag:</h4>
           <p className="text-under-link">The Mehendi is one of the best pre-wedding rituals of Indian weddings. It is currently regarded as one of the finest occasions for stunning photos in the sunlight. Day Mehendi is currently popular, and brides attend these events with their own swag.</p>
           <p className="text-under-link">Brides have a unique appearance at this event and stand out from the crowd with flowery or traditional jewelry and freshly applied Mehendi. Since it is now a daylight event, the brightly colored attire, decorations, and beverages in the guests hands are perfect for stunning photographs. However, only a professional candid wedding photographer will know where to look for the most stunning photos. People are usually walking around at wedding functions, so Expecting them to stand in one spot and pose can be unfair and can destroy the party atmosphere</p>

           <h4 className="dark-text mt-2 mb-2">The Family:</h4>
           <p className="text-under-link"> Even if the bride and groom are the focus of the wedding, one cannot ignore the happy family members. Nowadays, with the arrival of choreographed dances involving the entire family, it is essential for wedding photos to include them as well. But not everyone has the ability to record these unique moments when the family is celebrating together.</p>
           <p className='text-under-link'>
           To make sure you don't miss these moments, your candid wedding photographer or another experienced photographer from their staff will be present around your family. These photographers also take care not to bother your family while having them pose for photos.
           </p>
           <p className='text-under-link'>
           They make use of the situation the most and ensure that you receive stunning photos that you will remember for years to come.
           </p>
           <p className='text-under-link'> Must Read:
            <Link to ="/reasons-to-hire-a-professional-wedding-photographer">“Importance Of Hiring A Professional Wedding Photographer”</Link> </p>

           <h4 className='dark-text mt-2 mb-2'>Look Away And You Miss Moments: </h4>
           <p className='text-under-link'>Even the strongest hearts might be softened by the wedding's emotional intensity. Old friends you haven't seen since high school show up to support you, and even the pandit shocks you in the middle of a ceremony by giving you life-choice 2-roads-diverge-advice!</p>
           <p className='text-under-link'>
           A candid wedding photographer is prepared to capture these significant moments. We frequently request that the couple introduce me as their friend at the wedding. The families are more comfortable because of this, and we can easily walk about the gathering to capture these specific moments. We take a lot of photos that the participants aren't aware of since we choose to use the flash rarely.
           </p>
            <p className='text-under-link'>
               When a mother of the bride or a relative notices a photo of herself dropping a tear or laughing heartily and remembers that specific moment, it makes our day! They would be happy to have a moment in time when they were captured. We are sure, They will appreciate this.
               </p>

               <h4 className='dark-text mt-2 mb-2'>Natural Expressions:</h4>
               <p className='text-under-link'>
               One of the best benefits of selecting a <Link to="/contact">professional wedding photographer in Hisar</Link>  is that the photographer keeps the individuals and environment natural expressions while capturing the finest pictures. A picture is attractive because of the characters' natural expressions. Since the photographer does not specifically ask you to pose for the camera during candid camera work, you may not even be aware that you are being photographed. In this manner, the subjects' natural beauty as well as their actual essence and expressions are properly kept and captured. Some examples of these spontaneous reactions are your father crying quietly, your grandma laughing out loud, etc.
               </p>
               <h4 className='dark-text mt-2 mb-2'>Friends From Far Away:</h4>
               <p className='text-under-link'>
               Everyone has those school buddies they haven't seen for a long time. Include your close friends. Then there are the college or secondary school groups that used to have 3/6 tea from the bakery near campus.
               </p>
               <p className='text-under-link'>
               You haven't seen many of your buddies in a while, have you? A wedding is essentially one huge get-together where your friends from Mumbai and Canada travel to support you. A candid wedding photographer does a good job of capturing the craziness and emotion that are well-known side effects in these scenarios.
               </p>
               <h4 className='dark-text mt-2 mb-2'>
               The Excited Baraat:
               </h4>
               <p  className='text-under-link'>
               If you are from the groom's side, then you know how eagerly everyone expects and waits for the baraat. It's the one occasion when dancing in front of a horse or a carriage on the road is completely allowed. The mood and vibe of a baraat is one of celebration and having a good time. You'll dance with your family and friends, invite the groom to join you, and when he joins, everyone will make him the center of attention. Modern baraats don't just feature a groom seated on a horse. Now, grooms may arrive at their weddings in jeeps, cars, and other exciting vehicles. These make for wonderful photographs, but only when captured with the eye of a candid photographer who is aware of what is special and must be the focus of the images.
               </p>

               <h4 className='dark-text mt-2 mb-2'>
               When Bride Enters:
               </h4>
               <p  className='text-under-link'>
               The main focus in Indian weddings is the bride. The moment she walks into the wedding ceremony, it seems like time has frozen and the queen of the world has arrived from heaven. She is the focus of everyone's attention among her family and friends, and she is well-dressed in her elegant wedding attire.
               </p>
               <p className='text-under-link'>
               Since India has such a wide range of cultural traditions, and weddings are the function when this variety is most evident, No two brides will ever look the same. This is a great time to be captured, and only a candid wedding photographer can give it justice. It doesn't matter if she is with her friends and relatives or under a “phoolon ki chadar” surrounded by the affection of her brothers.
               </p>

               <h4 className='dark-text mt-2 mb-2'>
               Surprises:
               </h4>
               <p  className='text-under-link'>
               The emotional, entertaining, adoring, and loving moments of surprise are well captured in candid photographs. Once the photos are printed, couples, friends, and family members enjoy nice surprises because they were fully unaware that their photographs were being captured. Consequently, when the final copy of the wedding album shows up, it automatically makes many people happy
               </p>

               <h4 className='dark-text mt-2 mb-2'>
               Behind The Scenes:
               </h4>
               <p  className='text-under-link'>
               Mehndi, Sangeeth, and Haldi are beautiful aspects of traditional Indian weddings. They are important rituals with stunning surroundings and great religious significance. There is another aspect of an Indian wedding that is frequently hidden and unrecorded. the preparations.
               </p>
               <p  className='text-under-link'>
               A basic scenario is a bride getting ready for any of these occasions. To accomplish this, an army is frequently needed. The make-up artist, the patient sister who will watch over the precious jewelry, the sibling who can drive in an emergency, and the impatient dad who keeps asking what's taking so long.

               </p>

               <p  className='text-under-link'>
               My favorite aspect of candid wedding photography is capturing the bride and groom getting ready. Seeing a bride and groom getting ready for her special day is something we find quite heartwarming.
               </p>
               <p  className='text-under-link'>
               A bride goes through several stages during this period. She is also becoming aware that this is the day her entire life will change. It would be unfair to expect a regular photographer to be able to catch all of the details of this phase of the wedding day, which is why you need a candid wedding photographer who can detect the bride's emotions and take amazing photographs of her in response.
               </p>

               <h4 className='dark-text mt-2 mb-2'>
               Wrapping Up:
               </h4>
               <p  className='text-under-link mb-3'>
               It is impossible to match the skill of candid wedding photography. It isn't cheap, and you won't find it anywhere other than from a skilled candid wedding photographer who is familiar with the complexities of wedding photography. A candid wedding photographer works so hard to capture every detail of this special occasion because Indian weddings are colorful, vibrant, and unique. This ensures that you will have priceless memories for the rest of your life. Therefore,<Link to="/contact">hire a candid wedding photographer</Link>  and be sure to get wonderful images of your special day
               </p>
              
            </Col>
     
      </Container>
      
    </>
  )
}

export default BlogFour
