import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import logo from './logo.png';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
function MainNavBar() {
  return (
    <>
      <Navbar className='dark-bg' bg="light" variant="light" expand="lg">
      <Container> 
        <Navbar.Brand><img src={logo} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav bg-white" id="dropdown-split-basic"/>
        <Navbar.Collapse id="basic-navbar-nav" className='menu-link-right'>
          <Nav className="">
            <Nav><Link to="/">Home</Link></Nav>
            <Nav><Link to="/photos">Photos</Link></Nav>
            <Nav><Link to="/videos">Films</Link></Nav>
            <Nav><Link to="/about">About Us</Link></Nav>
            <Nav><Link to="/blog">Blog</Link></Nav>
            <Nav><Link to="/contact">Contact Us</Link></Nav>
            <Nav>
            <a href="https://www.facebook.com/Aggarwaldigitalstudiohisar/"><i className="bi bi-facebook"></i></a>
            </Nav>
            <Nav>
            <a href="https://www.instagram.com/aggarwaldigitalstudio/"><i className="bi bi-instagram"></i></a>
            </Nav>
            <Nav>
            <a href="https://api.whatsapp.com/send?phone=+919034002889&text=I%27m%20interested%20in%20your%20services&source=&data=&app_absent="><i className="bi bi-whatsapp"></i></a>
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default MainNavBar;