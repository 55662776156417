import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import about from '../img/about.jpg';

function AboutSection(props) {
  const{aboutContentfirst,aboutContentSecond,aboutButton,aboutClassName} = props

  return (
    <>
    <Row className='mt-5'>
        <Col md={6} lg={6}>
            <p className='light-gry-text'>About Us <b>-</b></p>
            <h2 className='dark-text mb-4 f-3'><span>Aggarwal Digital Studio</span></h2>
            <p>
           {aboutContentfirst}
            </p>
            <p>
            {aboutContentSecond}
            </p>
          <Row className='mt-3 mb-3 dark-text '>
          <Col md={6}>  <p className="fs-4"> <i class="bi bi-check-circle "></i> Quality Products</p></Col>
          <Col md={6}><p className="fs-4"> <i class="bi bi-check-circle"></i> Custom Products</p></Col>
          <Col md={6} className='mt-2'><p className="fs-4"> <i class="bi bi-check-circle"></i> Online Order</p>
          </Col>
          <Col md={6} className='mt-2'><p className="fs-4"> <i class="bi bi-check-circle"></i> Home Delivery</p>
           </Col>
        </Row>
            <div className='mt-3 mb-3'>
            <Link className={aboutClassName} to="/about">{aboutButton}</Link>
            </div>
            
        </Col>
        <Col md={6} lg={6} className='mt-3 mb-3'>
            <img src={about}/>
           
        </Col>
       
    </Row>
    </>
  );
}

export default AboutSection;