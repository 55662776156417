import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

export const ClientReview = () => {
    return (
      <div className='client-bg'>
         <h2 className='text-center text-white'>Reviews from our clients further proves our claim of<br/> being one of The Best Wedding Photographers in Hisar</h2>
        <Carousel>
          <Carousel.Item>
            <div className='text-center client-review'>
                {/* <img src="http://www.thekeyprojectre.com/wp-content/uploads/2016/04/demo-client.png"/> */}
                <h3>Vishal K</h3>
                <p>Aggarwal Digital Studio staff is quite helpful and their photographic work is amazing and fast. The best thing about Aggarwal Studio is they listen to their clients' problems no matter if it's their fault or not and help them free of cost without any questions. I will Recommend Aggarwal Digital Studio as an Best photographer in Hisar.</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='text-center client-review'>
                {/* <img src="http://www.thekeyprojectre.com/wp-content/uploads/2016/04/demo-client.png"/> */}
                <h3>A K</h3>
                <p>Great experience... Best Quality Digital Studio and Colour Lab for Photos and videos. Mobile Cover with photo, Cup with Photo, Photo Frames, Albums etc. All facilities are available under the roof. Passport photos within 5 min. As per Quality, prices are not too high.</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='text-center  client-review'>
                {/* <img src="http://www.thekeyprojectre.com/wp-content/uploads/2016/04/demo-client.png"/> */}
                <h3>Sandeep B</h3>
                <p>I had a very great experience with the Aggarwal Digital Studio team. We feel shy while capturing photos  but their team makes us feel so comfortable that we come out with our best and the result is unbelievable.<br/>
                  Even personally i suggest u should select Aggarwal Digital Studio for capture your lifetime moments</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='text-center  client-review'>
                {/* <img src="http://www.thekeyprojectre.com/wp-content/uploads/2016/04/demo-client.png"/> */}
                <h3>Geetika Gupta</h3>
                <p>We had an amazing experience with the The Aggarwal Digital Studio. They have the adopted modernity in their work. The photography is amazing. The edits are beautiful. Very hardworking team.</p>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      );
}
