import React from "react";
import { Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import s1 from '../img/s1.jpg'
import s2 from '../img/s2.jpg'
import s3 from '../img/s3.jpg'
import s4 from '../img/s4.jpg'
import s5 from '../img/s5.jpg'
import s6 from '../img/s6.jpg'
import s7 from '../img/s7.jpg'
import s8 from '../img/s8.jpg'
import s9 from '../img/s9.jpg'
import s10 from '../img/s10.jpg'
import s11 from '../img/s11.jpg'

export const MultiImages = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="mt-5 mb-5">
      <Row>
            <p className='light-gry-text'>Our Work <b>-</b></p>
            <h2 className='dark-text mb-4'><span>PHOTOS - Aggarwal Digital Studio</span></h2>
      </Row>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlay={true}
        autoPlaySpeed={1500}
        keyBoardControl={true}
        customTransition="all .10"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <div className="multi-img"><img src={s1}/></div>
        <div className="multi-img"><img src={s2}/></div>
        <div className="multi-img"><img src={s3}/></div>
        <div className="multi-img"><img src={s4}/></div>
        <div className="multi-img"><img src={s5}/></div>
        <div className="multi-img"><img src={s6}/></div>
        <div className="multi-img"><img src={s7}/></div>
        <div className="multi-img"><img src={s8}/></div>
        <div className="multi-img"><img src={s9}/></div>
        <div className="multi-img"><img src={s10}/></div>
        <div className="multi-img"><img src={s11}/></div>
      </Carousel>
    </div>
  );
};
