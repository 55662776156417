import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Col, Container,Row} from 'react-bootstrap'
import AboutSection from './components/AboutSection'
import { AboutTeam } from './components/AboutTeam'
import { ClientReview } from './components/ClientReview'
import { MultiImages } from './components/MultiImages'
import SiteSlider from './components/SiteSlider'

export const Home = () => {
  // useEffect(() => {
  //   window.scrollTo({ top:0, behavior: 'smooth' })
  // }, [])
  return (
    <>
    <SiteSlider/>
    <Container>
<AboutSection aboutContentfirst ="The Art of Photography is all about capturing the ideal moments that provide joy and pleasure by closely connecting the senses to the emotions. It's a basic task that requires careful handling of every detail in order to capture the moment as it should be. Experience, patience, and precision are all needed.
Events like weddings are about customs and rituals, significant events, discovery journeys, and new partnerships. To tie them all together you need something strong, beautiful and something everlasting. With our professional Candid Photography Services, True Shades Photography produces for your lovely memories that go up with time and place."
aboutContentSecond="We are among India’s top wedding photographers, we offer the best wedding photography services in Hisar and outside HIsar. Our team has the experience to make your moment memorable. In addition to wedding photography, we also offer the best pre-wedding photography Services, Fashion Photography, Baby Photo shoot in Hisar."

aboutButton="Read-More" aboutClassName ="
link-button" />
     
    </Container>
    <Container fluid>
    <MultiImages/>
    </Container>
    <ClientReview/>
    {/* <Container>
      <VideoSection/>
    </Container> */}
    <AboutTeam/>
    <Container>
      <Col className='text-center mt-5 mb-2'><h4>WHY CHOOSE US!</h4></Col>
      <Col className='text-center mb-5 dark-text'><h2 className='f-3'>The Leading Photo Studio In The City</h2></Col>
      <Row className=' mb-5'>
        <Col md={4}> <Card className='pt-3 pb-3 shadow-lg'>
      <Card.Body>
        <Card.Title className='fs-1 dark-text'>35</Card.Title>
        <Card.Subtitle className="mb-2">Award Winning</Card.Subtitle>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
       
      </Card.Body>
    </Card> </Col>
        <Col  md={4}> <Card className='pt-3 pb-3 shadow-lg'>
      <Card.Body>
        <Card.Title className='fs-1 dark-text'>45</Card.Title>
        <Card.Subtitle className="mb-2">Years Experience</Card.Subtitle>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
      </Card.Body>
    </Card> </Col>
        <Col  md={4}> <Card className='pt-3 pb-3 shadow-lg'>
      <Card.Body>
        <Card.Title className='fs-1 dark-text'>12345</Card.Title>
        <Card.Subtitle className="mb-2">Happy Clients</Card.Subtitle>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
      </Card.Body>
    </Card> </Col>
      </Row>
    </Container>
    </>
  )
}
