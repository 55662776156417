import React,{useEffect} from 'react'
import { useSpring,Spring, animated } from 'react-spring'
import { Card, Container, Row } from 'react-bootstrap';
import s1 from './img/s1.jpg'

const Film = () => {
    const styles = useSpring({
        loop: true,
        from: { rotateZ: 0 },
        to: { rotateZ: 180 },
      })
  return (
    <>
     <Container>
     <animated.div
      style={{
        width: "auto",
        height: "auto" ,
       backgroundImage: 'url(' + s1 +  ')',
        borderRadius: 16,
        ...styles,
      }}
    />
</Container>
    </>
  )
}

export default Film
