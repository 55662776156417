import React, { useRef } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ClientReview } from './components/ClientReview';
import contactus from './img/contactus.jpg';
import emailjs from '@emailjs/browser';
import {useEffect} from 'react';

function Contact() {
  useEffect(() => {
    window.scrollTo({ top:0, behavior: 'smooth' })
  }, [])
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    

    emailjs.sendForm(
      'service_blwg757', 
      'template_6bxryxq',
       form.current, '6RxOnXmcuzYrCVkA5')
      .then((result) => {
        alert('Mail sent success')
        e.target.reset();
          // console.log(result.text, "sendEmail success");
      }, (error) => {
          console.log(error.text, "error");
      });
  };
  return (
    <>
   
    <Col className='mb-5'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3484.0026793100924!2d75.7219338!3d29.1645933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391232d105409c0d%3A0x1698e8ec76a39d67!2sAggarwal%20Digital%20Studio%20and%20Color%20Lab!5e0!3m2!1sen!2sin!4v1664523437960!5m2!1sen!2sin" width="100%" height="350" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Col>
        <Container>
    <Row className='mt-5 mb-5'>
        <Col md={6}>
          <Form  ref={form} onSubmit={sendEmail}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Name" name="user_name"   />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="number" placeholder="Enter Phone Number" required name="user_phone number" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter Email" name="user_email"/>
          </Form.Group>
          <Button variant="primary" type="submit" className='mb-5'>
            Connect With Us
          </Button>
        </Form>
      </Col>
      <Col md={6}>
        <img src={contactus}/>
      </Col>
    </Row>
    </Container>
    <ClientReview/>
    </>
  );
}

export default Contact;