import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import w1 from "./img/slide1.jpg";
import w2 from "./img/slide3.jpg";
import w3 from "./img/slide4.jpg";
import w4 from "./img/slide6.jpg";
import w5 from "./img/slide7.jpg";
import w6 from "./img/slide8.jpg";
import w7 from "./img/slide9.jpg";
import w8 from "./img/slide10.jpg";
import w9 from "./img/slide12.jpg";
import w10 from "./img/slide13.jpg";
import w11 from "./img/slide14.jpg";
import w12 from "./img/slide15.jpg";
import w13 from "./img/slide16.jpg";
import w14 from "./img/slide20.jpg";
import w15 from "./img/slide21.jpg";
import w16 from "./img/slide23.jpg";
import w17 from "./img/slide24.jpg";

import p1 from "./img/slide2.jpg";
import p2 from "./img/slide11.jpg";
import p3 from "./img/slide17.jpg";
import p4 from "./img/slide18.jpg";
import p5 from "./img/slide19.jpg";
import p6 from "./img/slide22.jpg";
import p7 from "./img/slide31.jpg";

import baby1 from "./img/slide25.jpg";
import baby2 from "./img/slide26.jpg";
import baby3 from "./img/slide27.jpg";
import baby4 from "./img/slide28.jpg";
import baby5 from "./img/slide29.jpg";
import baby6 from "./img/slide30.jpg";
import baby7 from "./img/slide5.jpg";
import {useEffect  } from "react";

function Photos() {
  useEffect(() => {
    window.scrollTo({ top:0, behavior: 'smooth' })
  }, [])
  const weddingPhotos = [w1, w2, w3, w4, w5, w6, w7, w8, w9, w10, w11, w12, w13, w14, w15, w16, w17]
  const preWedding = [p1, p2, p3, p4, p5, p6, p7]
  const babyPhotos = [baby1, baby2, baby3, baby4, baby5, baby6, baby7]
  return (
    <>
      <Container>
        <div className="mt-5 mb-5">
          <p className="light-gry-text">
            Our Work <b>-</b>
          </p>
          <h2 className="dark-text">
            <span>Wedding Photos - Aggarwal Digital Studio</span>
          </h2>
        </div>
        <Carousel>
          {weddingPhotos.map((value, index) => {
            return(
               <Carousel.Item key={index}>
                <img className="d-block w-100" src={value} alt="First slide" />
              </Carousel.Item> 
            )
          })}
        </Carousel>
        {/* second */}
        <div className="mt-5 mb-5">
          <h2 className="dark-text">
            <span>Pre Wedding - Aggarwal Digital Studio</span>
          </h2>
        </div>
        <Carousel>
          {preWedding.map((value, index) => {
            return(
               <Carousel.Item key={index}>
                <img className="d-block w-100" src={value} alt="First slide" />
              </Carousel.Item> 
            )
          })}
        </Carousel>
        {/* third */}
        <div className="mt-5 mb-5">
          <h2 className="dark-text">
            <span>Baby Shoot - Aggarwal Digital Studio</span>
          </h2>
        </div>
        <Carousel>
        {babyPhotos.map((value, index) => {
            return(
               <Carousel.Item key={index}>
                <img className="d-block w-100" src={value} alt="First slide" />
              </Carousel.Item> 
            )
          })}
        </Carousel>
      </Container>
    </>
  );
}

export default Photos;
