
import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import about from './img/about.jpg';
import prewedding from './img/Pre-wedding Shoot.png';
import {useEffect} from 'react';
import bannerOne from "./img/slide2.jpg";

const BlogTwo = () => {
  useEffect(() => {
    window.scrollTo({ top:0, behavior: 'smooth' })
  }, [])
  return (
    <>
     <Container>
     <Col className='mt-5 '>
     <h2 className="dark-text mb-4"> 
     Top 10 Most Beautiful Pre-wedding Shoot Destinations of India.
                 </h2>
                 <img src={bannerOne}/>
              <h2 className="dark-text mt-2 mb-2"> Best Pre-wedding Shoot Destinations in India</h2> 
                <p className="text-under-link">
                <Link to="/photos">Pre-wedding photoshoots</Link>  are for couples who want beautiful pictures of their relationship without having to deal with the difficulties of wearing bulky wedding clothes, wearing jewelry, and having a large crowd of people around. A pre-wedding shoot is more than simply a chance to take lovely pictures in front of a lovely location. Ahead of your wedding day, you have the chance to make memories with your partner and strengthen your relationship in the process.
                </p>
                <p className="text-under-link">
                You two become closer during this emotional affair, preparing you for a lifetime of companionship. You need to find the best destination for your pre-wedding photography and also the best professional photographer for a pre-wedding shoot that fits your preferences and budget. If you want to make the entire event more spectacular and memorable, you also need to hire the best pre-wedding photographer to capture the most precious moment with your life partner.</p>
                <p className="text-under-link"> Therefore, we have put together a list of affordable locations in India for pre-wedding photographs that will make you fall in love right away.</p>
                <p className="text-under-link"> Let's look at the top 10 pre-wedding shoot destinations in India. </p>

                <h2 className="dark-text mt-2 mb-2"> 1. Taj Falaknuma Palace, Hyderabad</h2>
                
                <p className="text-under-link">
                Taj Falaknuma Palace is one of the best destinations for pre-wedding shoots situated in Hyderabad. This is the first choice of celebrities as their abode for both stays and weddings. The Nizami Charm of this place completely complements modern love and makes it the hottest destination in India for Couples who want a royal pre-wedding Photoshoot. The approximate cost of having a pre-wedding shoot done here is 2 lakhs with extra GST for photography. The specialty of the place is its architecture and the various backdrops available; a few places to visit hereby are Hebron Church and Chowmahalla Palace.
                </p>
            <h4 className="dark-text mt-2 mb-2">2. National Rail Museum, Delhi </h4>
            
            <p className="text-under-link" > Metro cities are probably not the first place that comes to mind when you hear about the pre-wedding photoshoot locations. The National Rail Museum is a unique location, which is not one of the typical pre-wedding photoshoot locations. It will give your pre-wedding photo session a fresh feel. Your pre-wedding photo shoot will look best against the natural beauty of the surrounding area and the Architecture charm of vintage trains. Popular pre-wedding destinations in India are becoming increasingly crowded as more individuals choose them. Additionally, if you dress in British attire, the photographs would seem to be of vintage and royal British Era.</p>

            <h4 className="dark-text mt-2 mb-2">3. Ladakh</h4>
            
            <p className="text-under-link">Ladakh's beauty and views don't require any justification. You must have all heard about its peacefulness since you were teenagers. Many of us frequently come up with different plans to travel to Ladakh with companions. Even while your trip to Ladakh with your friends may or may not have been a success, the pre-wedding photo shoot for your wedding will undoubtedly be a success in locations like Ladakh.</p>
            <p className="text-under-link">Everything will seem more positive for you because of the river's pure tones, the peaceful surroundings, and the adventure blended in. You also don't need to worry about the cost because all you need to spend is the ticket money to get a wonderful experience.</p>
           <h4 className="dark-text mt-2 mb-2">4. Panna-Meena Kund, Jaipur</h4>
           
           <p className="text-under-link">Panna-Meena Kund, though Jaipur is rich in vibrant beauty and interesting pre-wedding shoot locations, particularly creates the mood. A room is located on the northern wall of the square-shaped step well known as Panna Meena ka Kund, which has adjacent steps on all four sides. This space is said to have been utilized for religious rituals before marriages or at significant festival times. It is an 80-story step-well that can be seen in Jaipur City on Amer Road. The step-wells, which were built in the 18th century, can fully satisfy your strong cravings for history.</p>
           <p className="text-under-link">The location's historical importance gives it value and makes it a popular pre-wedding destination for couples. Again, you just need to pay for your ticket and the photos; there is no further charge for the photography</p>

           <h4 className="dark-text mt-2 mb-2">5. French Colonies, Pondicherry</h4>
           

<p  className="text-under-link">Pondicherry is the ideal location if you are bored of traveling to Goa for your pre-wedding photography and want to avoid the crowds of a popular town. The French Colonies in Pondicherry, which are in southern India, are an excellent place for a pre-wedding photo shoot.
</p>
<p  className="text-under-link">The beaches and elegant, colorful structures of the French colonies are well-known. The vibrant background will give your shot the extra charm you've always wanted. Another possibility for beach photography is the beaches in Pondicherry. Your journey to Pondicherry will provide you with priceless memories.</p>

<h4  className="dark-text mt-2 mb-2">6.The Taj Mahal, Agra
</h4>

<p  className="text-under-link">TYou may have occasionally heard the phrase "Love is in the air," but sometimes people fail to understand what it means. One location where these words come to life is the Taj Mahal. The Taj Mahal, which is also known as the "Crown of the Palace," is superior to all other mausoleums in terms of quality. It is constructed of ivory-white marble and is located on the Yamuna River's bank in the Indian city of Agra.
</p>
<p  className="text-under-link">
However, there is no need to describe this place. The Taj Mahal, one of the seven wonders of the world and the symbol of everlasting love, you cannot find any place more romantic than this for a pre-wedding photo shoot. Each image will come to life due to the impressive structure made of white marble and the beautiful grounds. You'll also bring back a huge amount of lovely memories that were captured in front of a magnificent background in addition to the photos.
</p>
<h4  className="dark-text mt-2 mb-2">7. Alleppey, Kerala </h4>

<p  className="text-under-link">
Alleppey, which is also known as Alappuzha, is situated in Kerala; the southern state of India. The district of Alleppey, popularly known as Alappuzha, is the third most literate district in Kerala and is well known for its natural beauty. It also serves as the district's administrative center. In reality, the cleanliness initiatives have preserved its charm, and it was named the cleanest town in India for the year 2016
</p>
<p  className="text-under-link">
On the shore, a lighthouse was constructed, and the Laccadive Sea coast lies nearby. Alleppey is one of the top locations in Kerala for the pre-wedding shoot because of the surroundings and internal beauty of the town. The Couples may appreciate the freshness and have a great time.
</p>
<h4  className="dark-text mt-2 mb-2">8. Rishikesh: Lakshaman Jhula </h4>


<p  className="text-under-link">
This place is an incredible area for both the video and the photo shoot. Rishikesh is the clear choice for couples looking to travel off the beaten path and take beautiful photos in a unique but beautiful location. Rishikesh is really like heaven on earth.
</p>
<p className="text-under-link">
Rishikesh is one of the top locations in India for pre-wedding photography because of its combination of rugged mountains, clean waterways, and white sand that creates a scene that is nothing short of paradisiacal for photographers and couples.
</p>

<h4  className="dark-text mt-2 mb-2">9. Srinagar, Jammu and Kashmir</h4>

<p className="text-under-link">
Srinagar is a trending popular destination for those seeking a vacation. Srinagar is the perfect location for pre-wedding events and wedding photo sessions because of its abundance of stunning locations and mesmerizing natural beauty. Consider traveling across the beautiful Dal Lake on a houseboat as the sun is setting in the distance. Srinagar is one of the greatest pre-wedding photography destinations in North India. It is considered as every photographer's dream.
</p>

<h4  className="dark-text mt-2 mb-2">10. Gateway of India
</h4>

<p className="text-under-link">
The gateway of India can also be the entrance of joy in your marriage. This historic site may provide a beautiful, vintage backdrop for your pre-wedding photography. It is a monument that never loses its allure, mirroring the new couple's everlasting affection, regardless of the time of day or night, summer or winter. You can also add some photographs of a yacht. Riding on a yacht gives you and your partner a very special feeling.
</p>

<h4 className="dark-text mt-2 mb-2"> Wrapping Up:</h4>
<p className="text-under-link">
The pre-wedding shoot is the latest craze in the wedding industry. Photographers in the field have been experimenting with various settings for a pre-wedding shoot, which is seen to be a nice bonding activity to start the wedding celebrations. India has a variety of landscapes, from hills to beaches, so you may pick any location from the above-mentioned destination to celebrate the start of your new married life.
</p>

<p>
Hope this list helps you to find the best destination for a pre-wedding Photoshoot. Photography also plays an important role in a pre-wedding photoshoot or videography, so you need to hire the <Link to="/">best pre-wedding photographer</Link>  to initiate the second phase of your life named the wedding.
</p>

    </Col>
   
      </Container>
      
    </>
  )
}

export default BlogTwo
