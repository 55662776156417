import React from "react";
import { Container } from "react-bootstrap";
import { ClientReview } from "./components/ClientReview";
import { ShortBlog } from "./components/ShortBlog";
import banner from "./img/slide1.jpg";
import bannerOne from "./img/slide2.jpg";
import bannerTwo from "./img/slide3.jpg";
import bannerThree from "./img/slide4.jpg";

export const Blog = () => {
  return (
    <>
      <Container>
        <div className="mt-5 mb-5">
          <p className="light-gry-text">
            Our Blog <b>-</b>
          </p>
          <h2 className="dark-text">
            <span>BLOG - Aggarwal Digital Studio</span>
          </h2>
        </div>
        <ShortBlog toLink="/reasons-to-hire-a-professional-wedding-photographer" img={banner} title={"5 Reasons to Hire A Professional Wedding Photographer"} desc={"The most important occasion in every person's life is their wedding. It's essential to enhance and celebrate the special moments as well. The bride and groom, their families, and their friends all gather to celebrate and enjoy the wedding day. To make that day great, a lot of preparations and arrangements are required, including the appropriate wedding site, decorations, food, beverages, DJ, and many other things. "}/>
        <ShortBlog  toLink="/most-beautiful-pre-wedding-shoot-destinations-of-india" img={bannerOne} title={"Top 10 Most Beautiful Pre-wedding Shoot Destinations of India."} desc={"Pre-wedding photoshoots are for couples who want beautiful pictures of their relationship without having to deal with the difficulties of wearing bulky wedding clothes, wearing jewelry, and having a large crowd of people around. A pre-wedding shoot is more than simply a chance to take lovely pictures in front of a lovely location. Ahead of your wedding day, you have the chance to make memories with your partner and strengthen your relationship in the process."}/>
        <ShortBlog  toLink="/Wedding-Photography-9-Best-Tips-for-Wedding-Photographers"
         img={bannerTwo} title={"Wedding Photography – 9 Best Tips for Wedding Photographers"} desc={"Before you can understand how our tips will help your photography for weddings, It's important to understand what makes shooting a wedding ceremony and reception difficult. Wedding photography is totally different from other types of photography, wedding photography only offers one opportunity to get the perfect shot.One of the most meaningful days of a person's life may be captured via the nuanced art of wedding photography."}/>
        <ShortBlog  toLink="/Why-You-Need-A-Candid-Wedding-Photographer-for-your-wedding" img={bannerThree} title={"Why You Need A Candid Wedding Photographer for your wedding"} desc={"It seems quite boring to spend hours posing for photos on a stage with different individuals. But when non-professional photographers create the wedding album, most brides and grooms are forced to make that decision.Best Wedding photographers that capture candid moments can help with this. Indian wedding photographers are increasingly upgrading their techniques and rejecting static pictures of simply the bride and groom."}/>
      </Container>
      <ClientReview/>
    </>
  );
};
