import Carousel from "react-bootstrap/Carousel";
import banner from "../img/slide1.jpg";
import bannerOne from "../img/slide2.jpg";
import bannerTwo from "../img/slide3.jpg";
import bannerThree from "../img/slide4.jpg";
import bannerFour from "../img/slide5.jpg";

function SiteSlider() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={banner} alt="First slide" />
        <Carousel.Caption className="title-light-white-bg dark-text d-none d-xl-block">
          <h1>Aggarwal Digital Studio</h1>
          <h5>BEST WEDDING FILMMAKER OF THE YEAR -WEDDINGSUTRA AWARDS</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bannerOne} alt="Second slide" />
        <Carousel.Caption className="title-light-white-bg dark-text d-none d-xl-block">
          <h1>Aggarwal Digital Studio</h1>
          <h5>BEST WEDDING FILMMAKER OF THE YEAR -WEDDINGSUTRA AWARDS</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bannerTwo} alt="Third slide" />
        <Carousel.Caption className="title-light-white-bg dark-text d-none d-xl-block">
          <h1>Aggarwal Digital Studio</h1>
          <h5>BEST WEDDING FILMMAKER OF THE YEAR -WEDDINGSUTRA AWARDS</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bannerThree} alt="Third slide" />
        <Carousel.Caption className="title-light-white-bg dark-text d-none d-xl-block">
          <h1>Aggarwal Digital Studio</h1>
          <h5>BEST WEDDING FILMMAKER OF THE YEAR -WEDDINGSUTRA AWARDS</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={bannerFour} alt="Third slide" />
        <Carousel.Caption className="title-light-white-bg dark-text d-none d-xl-block">
          <h1>Aggarwal Digital Studio</h1>
          <h5>BEST WEDDING FILMMAKER OF THE YEAR -WEDDINGSUTRA AWARDS</h5>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default SiteSlider;
