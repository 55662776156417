import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ShortBlog = (props) => {
    const{img,title,desc,toLink} = props;
  return (
    <>
      <Row className="mt-4 mb-4">
            <Col md={3}>
            <Link to="">
                <img src={img}/>
            </Link>
            </Col>
            <Col md={9}>
            <Link to={toLink}>
                <h3 className="dark-text">{title}</h3>
                <p className="text-under-link">{desc}</p>
            </Link>
            </Col>
      </Row>
    </>
  );
};
