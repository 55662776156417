import React from 'react'
import { Link } from 'react-router-dom';
// import { Link} from 'react-router-dom';

export const AboutTeam = () => {
    return (
        <div className='bgslideTwo text-center'>
            <h1 className="dark-text mb-5 text-white"><span>FILMS - Aggarwal Digital Studio</span></h1>
            <Link className='link-button mt-5' to="/about">KNOW MORE</Link>
        </div>
      );
}
